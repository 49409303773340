import UAParser from 'ua-parser-js';
import storage from '../lib/storage';
const parser = new UAParser();

function getBrowserLanguage() {
  let lang;
  if (navigator.languages && navigator.languages.length) {
    lang = navigator.languages[0];
  } else if (navigator.userLanguage) {
    lang = navigator.userLanguage;
  } else {
    lang = navigator.language;
  }
  return lang;
}

let referrerHostname = '';
try {
  if (document.referrer) {
    let domain = new URL(document.referrer);
    referrerHostname = domain.hostname;
  }
} catch (e) {}

let timezone = '';
try {
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
} catch (e) {}

const userInfo = {
  browserName: parser.getBrowser().name || '',
  browserVersion: parser.getBrowser().version || '',
  deviceType: parser.getDevice().type || '',
  deviceModel: parser.getDevice().model || '',
  deviceVendor: parser.getDevice().vendor || '',
  osName: parser.getOS().name || '',
  osVersion: parser.getOS().version || '',
  engineName: parser.getEngine().name || '',
  engineVersion: parser.getEngine().version || '',
  cpu: parser.getCPU().architecture || '',
  langCode: getBrowserLanguage(),
  screenWidth: window.screen.availWidth,
  screenHeight: window.screen.availHeight,
  viewportWidth: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
  viewportHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
  referrer: document.referrer || '',
  referrerHostname,
  host: window.location.hostname || '',
  href: window.location.href || '',
  timezone,
};

export { userInfo };
