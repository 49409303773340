import socket from './lib/socket';
import store from './store';
import Cookies from 'js-cookie';
let uniqueView = Cookies.get('unique-view');

if (typeof uniqueView === 'undefined') {
  Cookies.set('unique-view', false, { expires: 1 });
  uniqueView = 'true';
}

socket.emit(
  'get_project',
  {
    projectId: window.chatimSettings.projectId,
    uniqueView,
  },
  (response) => {
    store.setState({
      widgetConfig: response.widgetConfig,
      uniqueView,
    });
  },
);
